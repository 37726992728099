<template>
  <div class="d-flex flex-wrap">
    <div  v-for="pkg in userPackages" :key="pkg.id" sm="6" md="4" class="mx-4 my-4" style="width:400px;" >
      <UserPackageDatasets :userPackage="pkg" @onRevokeAccess="revokeAccess"/>
    </div>
  </div>
</template>

<script>
   import {getUserPackages, revokeAccessToDatasets, getUserPackageDatasets} from '@/api/users.js'
   import  UserPackageDatasets from '@/components/UserPackageDatasets'

  export default {
    name:'UserDatasets',
    components: {UserPackageDatasets},
    data () {
      return {
        userId : this.$route.params.id,
        userPackages:[],
        userPackageDatasets:[]
      }
    },
    created () {
      this.loadDatasets()
    },
    methods: {
      loadDatasets(){
          const _this = this
          getUserPackages(this.userId, function(res){
            _this.userPackages = res.map(item => {
              item.datasets=[]
              return item
              })
            var i = 0;
            for( i = 0; i< _this.userPackages.length; i++){
              getUserPackageDatasets(_this.userId, _this.userPackages[i].id, function(res){
                const idx = _this.userPackages.map(item => {return item.id}).indexOf(res.packageId)
                _this.userPackages[idx].datasets = res.datasets
              } 
              )
            }
          })   
      },
      revokeAccess(packageId, datasets){
        const payload ={
          datasets: datasets
        }
        const _this = this
        revokeAccessToDatasets(this.userId, packageId,  payload, function(){
          _this.loadDatasets()
        }, 'Access to the selected databases revoked successfully')
      }
    }
  }
</script>